import StudentPaymentReceipt from "components/Reports/StudentPaymentReceipt";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import "../../../assets/css/exam-payment.css";
import { getLastThreeMonthsFeeByClassAssistants } from "actions/ClassAssistantLayout";
import dateFormat from "dateformat";
import { updateStudentPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import { getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { useReactToPrint } from "react-to-print";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import { getUser } from "actions/Auth";
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";
import { updateStudentBulkPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import StudentBulkPaymentReceipt from "components/Reports/StudentBulkPaymentReceipt";
import { getAttendanceCountByClassAssistants } from "actions/ClassAssistantLayout";
import { geStudentExamsByClassAssistant } from "actions/Exams";
import { updateExamFee } from "actions/Exams";
import StudentExamPaymentReceipt from "components/Reports/StudentExamPaymentReceipt";

const ClassAssistantExamPayments = () => {
  const receiptRef = useRef();
  const bulkReceiptRef = useRef();
  const barcodeInputRef = useRef(null);

  const [selectedTwoMonthsAgo, setSelectedTwoMonthsAgo] = useState(null);
  const [selectedLastMonth, setSelectedLastMonth] = useState(null);
  const [selectedThisMonth, setSelectedThisMonth] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [months, setMonths] = useState([]);
  const [studentClasses, setStudentClasses] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [barcodeInput, setBarcodeInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const [student, setStudent] = useState({});
  const [receiptData, setReceiptData] = useState({
    subject: "",
    grade: "",
    teacherName: "",
    invoiceNo: "",
    invoiceDate: "",
    paidAmount: "",
    studentName: "",
    barcode: "",
    exam: "",
  });

  const [bulkReceiptData, setBulkReceiptData] = useState({
    invoiceNo: "",
    invoiceDate: "",
    studentName: "",
    barcode: "",
    payments: [
      {
        subject: "",
        grade: "",
        year: "",
        teacherName: "",
        paidMonth: "",
        paidAmount: "",
        payType: ""
      }
    ]
  });

  const fetchData = async () => {
    const response = await getUser();
    setUserId(response.id);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    let newDate = `${year}-${month}`;
    let prevMonth = month - 1;
    let twoMonthsAgo = month - 2;

    if (month === 1) {
      prevMonth = `${year - 1}-12`;
      twoMonthsAgo = `${year - 1}-11`;
    } else if (month === 2) {
      prevMonth = `${year - 1}-01`;
      twoMonthsAgo = `${year - 1}-12`;
    } else {
      prevMonth = `${year}-${prevMonth}`;
      twoMonthsAgo = `${year}-${twoMonthsAgo}`;
    }
    setMonths([twoMonthsAgo, prevMonth, newDate]);
  };

  const groupByTeacherGradeSubject = async (data) => {
    const grouped = data.exam_fees.reduce((acc, item) => {
      const { classUserId, teacher_name, subject_name, grade_name } =
        item;
      const key = `${classUserId}`;

      if (!acc[key]) {
        acc[key] = {
          teacherName: teacher_name,
          subjectName: subject_name,
          gradeName: grade_name,
          items: [],
        };
      }

      acc[key].items.push(item);
      return acc;
    }, {});
    const result = Object.keys(grouped).map((key) => ({
      classUserId: key,
      teacherName: grouped[key].teacherName,
      subjectName: grouped[key].subjectName,
      gradeName: grouped[key].gradeName,
      items: grouped[key].items,
    }));
    setGroupedData(result);
  };

  let barcodeString = "";

  const handleKeyDown = async (event) => {
    const barcodePattern = /^[0-9]$/;
    if (barcodePattern.test(event.key)) {
      barcodeString += event.key;
      if (barcodeString.length === 7) {
        barcodeInputRef.current.value = barcodeString;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isInactive, setInactive] = useState(false);
  const [inactiveMsg, setInactiveMsg] = useState(null);

  const getStudentPay = async (barcode) => {
    if (barcode.length <= 7) {
      setBarcodeInput(barcode);
    } else if (barcode.length > 7) {
      barcode = barcode.slice(7);
      setBarcodeInput(barcode);
    }
    setBarcode(barcode);
    try {
      if (barcode.length === 7) {
        const studentDetails = await getStudentByClassAssistants(barcode);
        if (studentDetails.success === true) {
          setInactive(false);
          setInactiveMsg(null);
          setStudent(studentDetails.students);
          const examDetails = await geStudentExamsByClassAssistant(barcode);
          if (examDetails.exam_fees.length > 0) {
            setNullMessage(false);
            setStudentClasses(examDetails);
            setTotalAmount(0);
            await groupByTeacherGradeSubject(examDetails);
          } else {
            setStudentClasses(null);
            setNullMessage(true);
            setTotalAmount(0);
          }
        }
        else {
          setNullMessage(false);
          setInactive(true);
          setInactiveMsg('This student is not active.')
        }
      }
      else {
        setStudentClasses(null);
      }
    } catch (error) {
      setStudentClasses(null);
    }
  };

  const handleInputChange = async (event) => {
    setTotalAmount(0);
    setBulkPayments([]);
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      setBarcodeInput(value);
      if (name === "barcode") {
        await getStudentPay(value);
      }
    }
  };

  const resetSelections = () => {
    setSelectedTwoMonthsAgo(null);
    setSelectedLastMonth(null);
    setSelectedThisMonth(null);
    setStudentClasses(null);
    setGroupedData([]);
    setStudent({ registration_number: "" });
    setBarcodeInput("");
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedClass, setSelectedClass] = useState(null);

  const [classFees, setClassFees] = useState({
    update_class_fees: []
  });

  const makeAPayment = async (details) => {
    try {
      if (details.amount !== null) {
        setSelectedClass(details);
      } else {
        const currentDate = new Date();

        const payDetails = {
          classUserId: +details.classUserId,
          examId: +details.exam_id,
          paid_date: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
          userId: +userId,
          amount: details.exam_fee,
        };

        setClassFees((prevState) => ({
          ...prevState,
          update_class_fees: [...prevState.update_class_fees, payDetails]
        }));
        const response = await updateExamFee(payDetails);
        setReceiptData({
          teacherName: details.teacher_name,
          subject: details.subject_name,
          grade: details.grade_name,
          barcode: student.barcode,
          studentName: student.full_name,
          invoiceNo: response.data.invoice_number,
          paidAmount: response.data.amount,
          invoiceDate: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
          examName: details.exam_name,
        });
        if (response.success === true) {
          await getStudentPay(barcode);
          handlePrint();
        }
      }
    } catch (error) {
    }
  };

  const [visibleOptionIndex, setVisibleOptionIndex] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);

  const handleInputChange1 = async (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentListByAssistant(searchTerm);
      const filteredItems = response.data.data.filter((user) =>
        user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filteredItems);
    }
  };

  const [nullMessage, setNullMessage] = useState(false);
  const getStudentBySeacrh = async (student) => {
    try {
      setNullMessage(false);
      setFilteredUsers(null);
      const studentDetails = await getStudentByClassAssistants(student);
      setStudent(studentDetails.students);
      setBarcodeInput(student);
      setBarcode(student);
      const examDetails = await geStudentExamsByClassAssistant(student);
      if (examDetails.exam_fees.length > 0) {
        setNullMessage(false);
        setStudentClasses(examDetails);
        setTotalAmount(0);
        await groupByTeacherGradeSubject(examDetails);
      } else {
        setStudentClasses(null);
        setNullMessage(true);
        setTotalAmount(0);
      }
    } catch (error) { }
  };

  const [searchbar, setSearchBar] = useState(false);
  const [bulkPayments, setBulkPayments] = useState([]);


  return (
    <>
      <div className="mt--3 container-fluid assistant-container">
        <Card className="mt-4 mb-4 pb-4 shadow">
          <CardHeader className="border-0 assistantStudentHeader">
            <div
              className="headerLeft"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 className="mb-0 mr-2">Exam Payments</h3>
              <i
                class="fa-solid fa-magnifying-glass"
                style={{ cursor: "pointer" }}
                onClick={() => setSearchBar(!searchbar)}
              ></i>
            </div>
          </CardHeader>
          {searchbar ? (
            <div className="ml-2">
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange1}
                    placeholder="Search..."
                    style={{
                      borderBottomLeftRadius: searchItem !== "" ? "0px" : "",
                      borderBottomRightRadius: searchItem !== "" ? "0px" : "",
                    }}
                  />
                  {searchItem && (
                    <ul
                      style={{
                        position: "absolute",
                        width: "94%",
                        listStyleType: "none",
                        padding: "0",
                        border: "1px solid #ddd",
                        zIndex: 10,
                        backgroundColor: "white",
                        display: filteredUsers === null ? "none" : "",
                      }}
                    >
                      {filteredUsers?.map((option, index) => (
                        <li
                          key={index}
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                            cursor: "pointer",
                          }}
                          onClick={() => getStudentBySeacrh(option.barcode)}
                        >
                          {option.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
              </Col>
            </div>
          ) : null}
          <div className="ml-4 barcodeSearch">
            <FormGroup>
              <Input
                id="barcode"
                placeholder="Barcode"
                type="text"
                value={barcodeInput}
                onChange={handleInputChange}
                ref={barcodeInputRef}
                name="barcode"
                pattern="\d*"
                className="barcodeSearchInput"
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="student_id"
                placeholder="Student Id"
                type="text"
                value={student?.registration_number}
                name="student_id"
                disabled
                className="barcodeSearchInput"
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={resetSelections} color="primary">
                Reset
              </Button>
                <Button onClick={handlePrint} color="primary">
                  Last Receipt
                </Button> 
            </FormGroup>
          </div>
          {nullMessage ?
            <div style={{ paddingLeft: "20px" }}>
              {student.full_name} has not any exam!
            </div>
            : null}
          <div className="classList">
            <div className="bodySection">
              <div className="examPaymentOptions">
                {studentClasses !== null ? (
                  groupedData.map((studentClass, classIndex) => (
                    <Form key={classIndex} className="ml-4 mb-2 assistantStudentExamPayments">
                      <div className="classExDetails">
                        <div className="classLeft">
                          <h3>{studentClass.teacherName}</h3>
                          <p>{studentClass.subjectName} - {studentClass.gradeName}</p>
                        </div>
                        {visibleOptionIndex === classIndex ? (
                          <div className="visibleExamPayOptions" onClick={() => setVisibleOptionIndex(null)}>
                            <SlArrowDown />
                          </div>
                        ) : (
                          <div className="visibleExamPayOptions" onClick={() => setVisibleOptionIndex(classIndex)}>
                            <SlArrowUp />
                          </div>
                        )}
                      </div>
                      <div className="examHistory" style={{ display: visibleOptionIndex === classIndex ? "" : "none" }}>
                        {studentClass.items
                          .filter((classes) => classes.exam_fee > 0) // Only include items with exam_fee > 0
                          .map((classes, index) => (
                            <div className="paymentExOptions" key={index}>
                              <FormGroup tag="fieldset" className="fieldSetExamPayOptions">
                                <Label style={{ fontWeight: "bold", marginBottom: "0px" }}>
                                  {classes.exam_name}
                                </Label>
                                <br />
                                <Label style={{ fontWeight: "bold", color: "#777777" }}>Rs.{classes.exam_fee}</Label>
                              </FormGroup>
                              <div className="paymentBtn">
                                <FormGroup>
                                  <Button
                                    className={classes.amount !== null ? "paidBtn" : "unpaidBtn"}
                                    onClick={() => makeAPayment(classes)}
                                    disabled={classes.amount !== null}
                                  >
                                    {classes.amount !== null ? `Paid` : `Pay`}
                                  </Button>
                                </FormGroup>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Form>
                  ))
                ) : null}

              </div>
            </div>
            {studentClasses !== null ? (
              <div className="studentSection">
                <div className="profile-card">
                  <div className="imageStudent">
                    <img src={student.image_url ? student.image_url : 'https://nenasala-balangoda.ardillalabs.cloud/api/uploads/person-icon.jpg'} className="studentPaymentImage" />
                  </div>
                  <div className="studentDetailsClassAssistant">
                    <h3><i class="fa-solid fa-circle-user"></i> {student.full_name}</h3>
                    <p style={{ fontSize: "12px" }}><i class="fa-solid fa-registered"></i> {student.registration_number}</p>
                    {student.phone_number !== null ? <p><i class="fa-solid fa-phone-volume"></i> {student.phone_number}</p> : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Card>
        <div style={{ display: "none" }}>
          <StudentExamPaymentReceipt ref={receiptRef} allDetails={receiptData} />
        </div>
      </div>
    </>
  );
};

export default ClassAssistantExamPayments;