import axios from "axios";
const apiUrl = "https://nenasala-balangoda.ardillalabs.cloud/api";

export const getAllSuppliers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/supplier`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
        reject(err);
    }
  });
};

export const addNewSupplier = async (addFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.post(
        `${apiUrl}/supplier/create-supplier`,
        addFormData,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
            message: err.response.data.message,
            status: false,
            statusCode: 400
        });
    }
  });
};

export const editSupplier = async (editFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/supplier/update-supplier-info`,
        editFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
            message: 'Unable to update the Supplier. Try Again!',
            status: false,
            statusCode: 400
        });
    }
  });
};

export const getAllActiveSuppliers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
       `${apiUrl}/supplier?status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getSuppliersByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/supplier?skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

export const getSuppliersBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/supplier?skip=${skip}&limit=${limit}&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

//Supplier Order
export const addNewSupplierOrder = async (addFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.post(
        `${apiUrl}/supplier-order/create-supplier-order`,
        addFormData,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
            message: err.response.data.message,
            status: false,
            statusCode: 400
        });
    }
  });
};

export const filterSupplierOrders = async (date, serviceId, paymentType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      let url = `${apiUrl}/supplier-order?`;

      if (date !== undefined && date !== null) {
        url += `&date=${date}`;
      }

      if (serviceId !== null && serviceId !== undefined) {
        url += `&supplierId=${serviceId}`;
      }

      if (paymentType !== null && paymentType !== undefined) {
        url += `&payment_type=${paymentType}`;
      }
      const response = await axios.get(url, config);

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

export const editSupplierOrder = async (editFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/supplier-order/update-supplier-order`,
        editFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
            message: 'Unable to update the Supplier Order. Try Again!',
            status: false,
            statusCode: 400
        });
    }
  });
};