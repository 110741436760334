import React, { useEffect, useState } from 'react';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/nenasala-logo.png';
import BlankImage from '../../assets/img/brand/blank_profile_pic.png';
import Background from '../../assets/img/brand/nenasala-bg.jpg';

const StudentIdCard = ({ student }) => {

  return (
    <div style={styles.card}>
      <div style={styles.header}>
        <img src={Logo} alt="Institute Logo" width={90} crossOrigin="anonymous" />
      </div>
      <div style={styles.studentInfo}>
        <h5 style={{ marginBottom: '0px', height: '3mm', lineHeight: '1' }}>{student.name_with_initials}</h5>
        <img src={
          student.image_url
            ? student.image_url
            : BlankImage
        } style={styles.photoPlaceholder} crossOrigin="Access-Control-Allow-Origin" />
        <h6 style={{ marginBottom: '0px' }}>STUDENT ID CARD</h6>
      </div>
      <Barcode
        className="barCodeId"
        value={student.barcode}
        height={40}
        fontSize={12}
        margin={8}
        style={{ position: 'relative', zIndex: 1 }}
      />
      <div style={styles.contactInfoSec}>
        <p style={styles.contactInfo}>No 18/1, Kalthota Road, Balangoda</p>
        <p style={styles.contactInfo}>Tele: 0452289889 / 0452286646</p>
        <p style={styles.contactInfo}>WhatsApp: 0777775358</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    width: '55mm',
    height: '87mm',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    fontFamily: 'Arial, sans-serif',
    backgroundImage: `url(${Background})`,  
    backgroundSize: 'cover',            
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  header: {
    textAlign: 'center',
    marginBottom: '5px',
  },
  logo: {
    width: '40px',
    height: '50px',
  },
  instituteName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  instituteDetails: {
    fontSize: '12px',
    color: '#555',
  },
  studentInfo: {
    textAlign: 'center',
    padding: '0px 10px'
  },
  photoPlaceholder: {
    width: '86px',
    height: '102px',
    borderRadius: '50%',
    backgroundColor: '#d9d9d9',
    margin: '6px auto 2px auto',
  },
  barcode: {
    display: 'block',
    margin: '10px auto',
  },
  contactInfoSec: {
    textAlign: 'center',
    fontSize: '10px',
    color: '#333',
  },
  contactInfo: {
    textAlign: 'center',
    fontSize: '8px',
    color: '#333',
    lineHeight: '1.4'
  },
};

export default StudentIdCard;
