import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    InputGroup,
  } from "reactstrap";
  import { useState, useEffect } from "react";
  import SecondaryHeader from "components/Headers/SecondaryHeader";
  import { editStudent } from "actions/Students";
  import { getAllActiveGrades } from "actions/Grades";
  import { findTeachersByGradeId } from "actions/Teachers";
  import { findAllSubjectsByTeacherIdAndGradeId } from "actions/Subjects";
  import { BiEdit } from "react-icons/bi";
  import "../../../assets/css/styles.css";
  import Paginations from "components/Pagination/Paginations";
  import { filterStudentList } from "actions/Students";
  import { filterStudentListByClassAssistant } from "actions/ClassAssistantLayout";
import { updateStudentPaymentTypeByClassAssistant } from "actions/ClassAssistantLayout";
  
  const ClassAssistantStudentPayType = () => {
    const Status = {
        FULL: "FULL",
        HALF_FREE: "HALF_FREE",
        FULL_FREE: "FULL_FREE",
    };
  
    const [allStudents, setAllStudents] = useState(null);
    const [isAllStudents, setIsAllStudents] = useState(false);
  
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [editModal, setEditmodal] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isLoadingStudents, setLoadingStudents] = useState(true);
    const [isEditedInput, setEditedInput] = useState(false);
    const [isDisabledEditButton, setDisabledEditButton] = useState(true);
    const [allGrades, setAllGrades] = useState(null);
    const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
    const [selectedGradeForStudent, setSelectedGradeForStudent] = useState(null);
    const [allTeachersByGrade, setAllTeachersByGrade] = useState(null);
    const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [allSubjects, setAllSubjects] = useState(null);
    const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPge, SetItemsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);
    const [gradeFilterDropdownOpen, setGradeFilterDropdownOpen] = useState(false);
    const [subjectFilterDropdownOpen, setSubjectFilterDropdownOpen] = useState(false);
    const [teacherFilterDropdownOpen, setTeacherFilterDropdownOpen] = useState(false);
    const [selectedGradeFilter, setSelectedGradeFilter] = useState(null);
    const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
    const [selectedTeacherFilter, setSelectedTeacherFilter] = useState(null);
    const [allTeachers, setAllTeachers] = useState(null);
    const [allActiveSubjects, setAllActiveSubjects] = useState(null);
    const [filterStudent, setFilterStudent] = useState(false);
    const [isFilterError, setFilterError] = useState(false);
  
    const [searchQuery, setSearchQuery] = useState("");
    const [initialStudent, setInitialStudent] = useState({
      id: null,
      registration_number: "",
      full_name: "",
      phone_number: "",
      password: "",
      enrolled_classes: [],
      status: "",
    });
  
    const [isData, setIsData] = useState({
      full_name: "",
      enrolled_classes: [],
      phone_number: "",
    });
  
    const [isError, setIsError] = useState({
      full_name: "",
      email: "",
      phone_number: "",
    });
  
    const [isEditError, setEditError] = useState({
      full_name: "",
      phone_number: "",
    });
  
    const fetchData = async () => {
      try {
        const grades = await getAllActiveGrades();
        setAllGrades(grades);
        setLoadingStudents(false);
      } catch (error) {
        setLoadingStudents(false);
        setIsAllStudents(false);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const filterStudents = async (gradeId, subjectId, teacherId) => {
      try {
        setFilterStudent(true);
        const searched = await filterStudentListByClassAssistant(itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId);
        setPageCount(searched.data.pageCount);
        setIsAllStudents(true);
        setCurrentStart(0);
        setCurrentPage(skip + 1);
        setAllStudents(searched.data.data);
      } catch (error) {
        setPageCount(0);
        setFilterError(true);
        setAllStudents(null);
      }
    }
  
    const filterBySearch = async (itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId) => {
      const searched = await filterStudentListByClassAssistant(itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId); 
      setCurrentPage(skip + 1);
      setPageCount(searched.data.pageCount);
      setAllStudents(searched.data.data);
    }
  
    const resetFilter = async () => {
      setFilterStudent(false);
      setSelectedGradeFilter(null);
      setSelectedSubjectFilter(null);
      setSelectedTeacherFilter(null);
      setAllTeachers(null);
      setAllActiveSubjects(null);
      setSearchQuery("");  
      setCurrentStart(0);
      setCurrentPage(1);
    }
    const handlePagination = async (pageNumber) => {
      if (filterStudent) {
        await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery, selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id);
      } 
    }
  
    const getTeachersToFilter = async (gradeId) => {
    setAllStudents(null);
      const teachersByGrade = await findTeachersByGradeId(gradeId);
      setAllTeachers(teachersByGrade.filter(teacher => teacher.status = 'ACTIVE'));
    }
  
    const getSubjectsToFilter = async (gradeId, teacherId) => {
    setAllStudents(null);
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
      setAllActiveSubjects(subjectsByGradeAndTeacher.filter(subject => subject.status = true));
    }
    
    useEffect(() => {
    }, [isData]);
  
    const handleEditClick = (student) => {
      setSelectedStudent(student);
      setInitialStudent(student);
      setEditmodal(true);
    };
  
    const editStudentDetails = async () => {
        setEditedInput(false);
        const data = {id: selectedStudent.id, fee_type: selectedStudent.fee_type};    
        const body = JSON.stringify(data);
        const response = await updateStudentPaymentTypeByClassAssistant(body);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          await filterStudents(selectedGradeFilter.id, selectedSubjectFilter.id, selectedTeacherFilter.id)
          setEditmodal(false);
        } else {
          setFailed(true);
        }
    };

    return (
      <>
        <div className="mt-2 container-fluid assistant-container">
          <Modal
            className="modal-dialog-centered modal-success"
            isOpen={isSuccess}
            toggle={() => setSuccess(false)}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {isSuccessMessage}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setSuccess(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </Modal>
          <Modal
            className="modal-dialog modal-danger"
            isOpen={isFailed}
            toggle={() => setFailed(false)}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {isErrorMessage}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setFailed(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          </Modal>
          {isLoadingStudents ? (
            <Card style={{}}>
              <Spinner className="m-10">Loading...</Spinner>
            </Card>
          ) : (
            <Card className="shadow" style={{ padding: "1rem" }}>
              <CardHeader className="border-0">
                <Row className="mb-4">
                  <Col>
                    <h3 className="mb-0">Students</h3>
                  </Col>
                </Row>
                <Row className="filterClass">
                <Col md="8" lg="4">
                <FormGroup>
                    <InputGroup className="mb-3">
                      <Input
                        type="text"
                        placeholder="Student Name"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                    </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4" lg="4" style={{ paddingLeft: "15px" }}>
                    <FormGroup>
                      <Dropdown
                        isOpen={gradeFilterDropdownOpen}
                        toggle={() => setGradeFilterDropdownOpen(!gradeFilterDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedGradeFilter ? selectedGradeFilter.name : "Grade"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: 250, overflowY: "auto" }}
                        >
                          {allGrades?.map((grade) => (
                            <DropdownItem
                              key={grade.id}
                              value={grade.id}
                              onClick={() => [
                                setSelectedGradeFilter(grade),
                                setSelectedTeacherFilter(null),
                                setSelectedSubjectFilter(null),
                                getTeachersToFilter(grade.id),
                                setAllStudents(null)
                              ]}
                            >
                              {grade.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4" style={{ paddingLeft: "15px" }}>
                    <FormGroup>
                      <Dropdown
                        isOpen={teacherFilterDropdownOpen}
                        toggle={() =>
                          setTeacherFilterDropdownOpen(!teacherFilterDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedTeacherFilter
                            ? selectedTeacherFilter.full_name
                            : "Teacher"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: 250, overflowY: "auto" }}
                        >
                          {allTeachers?.map((teacher) => (
                            <DropdownItem
                              key={teacher.id}
                              value={teacher.id}
                              onClick={() => [
                                setSelectedTeacherFilter(teacher),
                                setSelectedSubjectFilter(null),
                                getSubjectsToFilter(selectedGradeFilter.id, teacher.id)
                              ]
                              }
                            >
                              {teacher.full_name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4" style={{ paddingLeft: "15px" }}>
                    <FormGroup>
                      <Dropdown
                        isOpen={subjectFilterDropdownOpen}
                        toggle={() =>
                          setSubjectFilterDropdownOpen(!subjectFilterDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedSubjectFilter
                            ? selectedSubjectFilter.name
                            : "Subject"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: 250, overflowY: "auto" }}
                        >
                          {allActiveSubjects?.map((subject) => (
                            <DropdownItem
                              key={subject.id}
                              value={subject.id}
                              onClick={() =>
                                setSelectedSubjectFilter(subject)
                              }
                            >
                              {subject.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  
                  <Col className="filterMobile">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => filterStudents(selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id)}
                      disabled={selectedGradeFilter === null || selectedGradeFilter === null ||selectedSubjectFilter === null}
                    >
                      Filter
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => resetFilter()}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              { !isAllStudents ? (
              <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
          ) : (
            <>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Reg. No</th>
                    <th scope="col">Barcode</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col" className="actionTh">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allStudents?.map((student, index) => (
                    <tr key={index}>
                      <td>{student.user.registration_number}</td>
                      <td>{student.user.barcode}</td>
                      <td>{student.user.full_name}</td>
                      <td>{student.user.phone_number}</td>
                      <td className="actionTd" style={{ textAlign: "center" }}>
                        <Button
                          color="primary"
                          type="button"
                          id="editStudent"
                          onClick={() => handleEditClick(student)}
                        >
                          <BiEdit />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div style={{ paddingTop: "10px" }}>
                {pageCount > 1 ?
                  <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                    currentStart={currentStart}
                    setCurrentStart={setCurrentStart}></Paginations>
                  : null}
              </div>
              </>
          )}
            </Card>
          )}
          <Modal
            className="modal-dialog-centered"
            isOpen={editModal}
            toggle={() => [setEditmodal(false)]}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Student
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => [
                  setEditmodal(false),
                  setDisabledEditButton(true),
                  setEditError({
                    fee_type: "",
                  }),
                ]}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form>
                <Row>
                  <Col>
                  <FormGroup>
                    <Dropdown
                      isOpen={statusDropdownOpen}
                      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedStudent
                          ? selectedStudent.fee_type
                          : "Select a Fee Type"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Status).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => [
                              setSelectedStudent({
                                ...selectedStudent,
                                fee_type: value,
                              }),
                              setEditedInput(true),
                              setDisabledEditButton(false),
                            ]}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => editStudentDetails()}
                      disabled={!isEditedInput || isDisabledEditButton}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div >
      </>
    );
  };

export default ClassAssistantStudentPayType;