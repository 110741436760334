import axios from "axios";
const apiUrl = "https://nenasala-balangoda.ardillalabs.cloud/api"; 
const authUrl = "https://nenasala-balangoda.ardillalabs.cloud/api/auth";

export const getAllClassAssistants = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users/get-all-card-markers`,
        config
      );
      
      if (response.status === 200) {
        resolve(response.data.card_markers);
      } else {
        reject(response.data.card_markers);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const createClassAssistant = async (createClassAssistantFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const responseUser = await axios.post(
        `${authUrl}/create-card-marker`,
        createClassAssistantFormData,
        config
      );

      if (responseUser.data.success === true) {
        resolve(responseUser.data);
      } else {
        reject(responseUser.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        resolve(err.response.data);
      }
    }
  });
};

export const editClassAssistant = async (editTeacherFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/users/update-card-marker-info`,
        editTeacherFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};


export const editAssistantPassword = async (editTeacherFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.put(
        `${authUrl}/create-new-password-for-teacher`,
        editTeacherFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getAllActiveClassAssistants = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/users/get-all-card-markers?status=true`,
        config
      );
      
      if (response.status === 200) {
        resolve(response.data.card_markers);
      } else {
        reject(response.data.card_markers);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};