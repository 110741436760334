import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
    Card, CardHeader, Form, Row, Col, FormGroup, Button,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Input,
    Modal,
} from "reactstrap";
import '../../../assets/css/class-assistant-payments.css';
import dateFormat from "dateformat";
import { getAllActiveServices } from 'actions/Services';
import { getUser } from 'actions/Auth';
import { addNewServicePayment } from 'actions/Services';
import ServicePaymentReceipt from 'components/Reports/ServicePaymentReceipt';
import { getAllActiveClassAssistants } from 'actions/ClassAsistants';

const ClassAssistantServicePayments = () => {

    const date = new Date();
    const [allActiveServices, setAllActiveServices] = useState(null);
    const [allAssistants, setAllAssistants] = useState(null);
    const receiptRef = useRef(null);
    const [paymentDetails, setPaymentDetails] = useState({
        invoiceNumber: '',
        invoiceDate: '',
        amount: '',
        service: '',
        description: '',
        nenasalaService: '',
        createdBy: ''
    });
    const [isData, setData] = useState({
        description: '',
        amount: '',
        paid_date: '',
        getUserId: '',
        nenasalaServiceId: '',
        staff_id: '',
    });

    const [isError, setError] = useState({
        description: '',
        amount: '',
        nenasalaService: '',
        assistant: '',
    });
    const [serviceTypeDropdownOpen, setServiceTypeDropdownOpen] = useState(false);
    const [selectedtServiceType, setSelectedtServiceType] = useState(null);
    const [assistantDropdownOpen, setAssistantDropdownOpen] = useState(false);
    const [selectedtAssistant, setSelectedtAssistant] = useState(null);
    const [user, setUser] = useState(null);
    const [isFailed, setFailed] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);

    const getServices = async () => {
        try {
            const services = await getAllActiveServices();
            setAllActiveServices(services.filter(services => services.status = 'ACTIVE'));
        } catch (error) {
            setAllActiveServices(null);
        }
    }

    const getActiveAssistants = async () => {
        try {
            const assistants = await getAllActiveClassAssistants();
            setAllAssistants(assistants);
        } catch (error) {
            setAllAssistants(null);
        }
    }
    const fetchData = async () => {
        try {
            const user = await getUser();
            setUser(user.id);
            await getServices();
            await getActiveAssistants();
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (event) => {
        setError({ ...isError, [event.target.name]: "" });
        if (event.target.name === 'amount') {
            if (/^\d*$/.test(event.target.value)) {
                setData({ ...isData, [event.target.name]: event.target.value });
            }
        } else {
            setData({ ...isData, [event.target.name]: event.target.value });
        }
    };

    const handlePrint = useReactToPrint({
        content: () => receiptRef.current,
    });

    const isValidPayment = (value) => {
        const errors = {};
        if (value.description.length < 3) {
            errors.description =
                "Description must be at least 3 characters long.";
        }
        if (!value.amount) {
            errors.amount =
                "Amount is Required.";
        }
        if (!value.nenasalaServiceId) {
            errors.nenasalaService =
                "Nenasala Service is Required.";
        }
        if (Object.keys(errors).length > 0) {
            setError(errors);
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (paymentDetails.invoiceNumber) {
            handlePrint();
        }
    }, [paymentDetails]);

    const insertNewPayment = async () => {
        try {
            if (isValidPayment(isData)) {
                setinsertButtonDisabled(true);
                isData.paid_date = date;
                isData.amount = +(isData.amount);
                isData.getUserId = +user;
                isData.nenasalaServiceId = +(isData.nenasalaServiceId);
                isData.staff_id = +(isData.staff_id);
                const response = await addNewServicePayment(isData);
                if (response.success === true) {
                    const currentDate = new Date();
                    setPaymentDetails({
                        invoiceNumber: response.data.invoice_number,
                        invoiceDate: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
                        amount: response.data.amount,
                        service: response.data.nenasala_service.name,
                        description: response.data.description,
                        createdBy: response.data.user.full_name
                    });
                    setData({
                        description: '',
                        amount: ''
                    });
                    setSelectedtServiceType(null);
                    setSelectedtAssistant(null);
                    setinsertButtonDisabled(false);
                } else {
                    setFailed(true);
                    setErrorMessage('Failed to make the payment. Try Again!');
                    setinsertButtonDisabled(false);
                }
            }
        } catch (error) {
            setFailed(true);
            setErrorMessage('Failed to make the payment. Try Again!')
        }
    }

    return (
        <>
            <div className="mt--3 container-fluid assistant-container">
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mt-4 mb-4 pb-4 shadow">
                    <CardHeader className="border-0 assistantStudentHeader">
                        <div className="headerLeft">
                            <h3 className="mb-0">Nenasala Services</h3>
                        </div>
                    </CardHeader>
                    <Form className="ml-4 mb-4 mr-4">
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Dropdown
                                        isOpen={serviceTypeDropdownOpen}
                                        toggle={() => setServiceTypeDropdownOpen(!serviceTypeDropdownOpen)}
                                    >
                                        <DropdownToggle caret>
                                            {selectedtServiceType
                                                ? selectedtServiceType
                                                : "Select a Nenasala Service"}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ height: '400px', overflowY: 'scroll' }}>
                                            {allActiveServices && [...allActiveServices]
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map((service) => (
                                                    <DropdownItem
                                                        key={service.name}
                                                        value={service.id}
                                                        onClick={() => [
                                                            setData({
                                                                ...isData, nenasalaServiceId: service.id
                                                            }),
                                                            setSelectedtServiceType(service.name),
                                                            setError({ ...isError, nenasalaService: '' })
                                                        ]}
                                                    >
                                                        {service.name}
                                                    </DropdownItem>
                                                ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                    {isError.nenasalaService && (
                                        <p className="text-danger">{isError.nenasalaService}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Dropdown
                                        isOpen={assistantDropdownOpen}
                                        toggle={() => setAssistantDropdownOpen(!assistantDropdownOpen)}
                                    >
                                        <DropdownToggle caret>
                                            {selectedtAssistant
                                                ? selectedtAssistant
                                                : "Select a Staff Member"}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ height: '400px', overflowY: 'scroll' }}>
                                            {allAssistants && [...allAssistants]
                                                .sort((a, b) => a.full_name.localeCompare(b.full_name))
                                                .map((assistant) => (
                                                    <DropdownItem
                                                        key={assistant.full_name}
                                                        value={assistant.id}
                                                        onClick={() => [
                                                            setData({
                                                                ...isData, staff_id: assistant.id
                                                            }),
                                                            setSelectedtAssistant(assistant.full_name),
                                                            setError({ ...isError, assistant: '' })
                                                        ]}
                                                    >
                                                        {assistant.full_name}
                                                    </DropdownItem>
                                                ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                    {isError.assistant && (
                                        <p className="text-danger">{isError.assistant}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="description"
                                        placeholder="Description"
                                        type="text"
                                        value={isData.description}
                                        name='description'
                                        required
                                        onChange={handleInputChange}
                                    />
                                    {isError.description && (
                                        <p className="text-danger">{isError.description}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="amount"
                                        placeholder="Amount"
                                        type="text"
                                        value={isData.amount}
                                        name='amount'
                                        required
                                        onChange={handleInputChange}
                                    />
                                    {isError.amount && (
                                        <p className="text-danger">{isError.amount}</p>
                                    )}
                                </FormGroup>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={insertNewPayment}
                                    disabled={isInsertButtonDisabled}
                                >
                                    Insert
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <div style={{ display: "none" }}>
                    <ServicePaymentReceipt ref={receiptRef} allDetails={paymentDetails} />
                </div>
            </div>
        </>
    );
}

export default ClassAssistantServicePayments;