import {
  Table,
  Card,
  CardHeader,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
  Modal,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useNavigate, Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/styles.css";
import "../../assets/css/toggle-button-styles.css";
import Paginations from "components/Pagination/Paginations";
import Messages from "components/Messages/Messages";
import { findAttendanceList } from "actions/Teachers";
import { findAbsentList } from "actions/Teachers";
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";
import { studentAttendanceReport } from "actions/Students";
import { getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { studentAttendanceReportRange } from "actions/Students";

const AttendanceReport = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = year + "-" + month + "-" + day;
  const navigate = useNavigate();
  const [teacherClasses, setTeacherClasses] = useState(null);
  const [isTeacherClasses, setIsTeacherClasses] = useState(false);
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [startDate, setStartDate] = useState(formattedDate);
  const [isChecked, setIsChecked] = useState(true);
  const [isFailed, setFailed] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isPresent, setPresent] = useState(true);
  const [isStudent, setStudent] = useState(null);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [attendanceList, setAttendanceList] = useState(null);
  const [isAttendanceList, setIsAttendanceList] = useState(false);
  const [isStudentAttendance, setStudentAttendance] = useState(null);

  const getAttendance = async (student, selectedDate) => {
    try {
      const response = await studentAttendanceReport(student, selectedDate);
      setTeacherClasses(response.data);
      setLoadingStudents(false);
      if (response.data.length > 0) {
        setIsTeacherClasses(true);
      } else {
        setIsTeacherClasses(false);
      }
    } catch (error) {

    }
  }

  const getAttendanceRange = async (student, startDate, endDate) => {
    try {
      const response = await studentAttendanceReportRange(student, startDate, endDate);
      setAttendanceList(response.data);
      if (response.data.length > 0) {
        setIsAttendanceList(true);
      } else {
        setIsAttendanceList(false);
      }
    } catch (error) {

    }
  }

  const fetchData = async () => {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setMonth(startDate.getMonth() - (12 - 1));
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const months = [];

    for (let year = startYear; year <= endYear; year++) {
      const monthStart = year === startYear ? startMonth : 0;
      const monthEnd = year === endYear ? endMonth : 11;

      for (let month = monthStart; month <= monthEnd; month++) {
        const monthString = `${year}-${(month + 1)
          .toString()
          .padStart(2, "0")}`;
        months.push({
          label: monthString,
          month: month + 1,
          year: year,
        });
      }
    }
    setMonths(months);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filterAttendanceByDate = async (date) => {
    setSelectedMonth(null)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setStartDate(newDate)
    await getAttendance(isStudent?.id, newDate);
  }

  const [searchItem, setSearchItem] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(null);

  const handleInputChange1 = async (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentListByAssistant(searchTerm);
      const filteredItems = response.data.data.filter((user) =>
        user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filteredItems);
    }
  }

  const getStudentBySeacrh = async (student) => {
    setFilteredUsers(null)
    const response = await getStudentByClassAssistants(student);
    setStudent(response.students);
    if (!selectedMonth) {
      await getAttendance(response.students.id, startDate);
    } else {
      await getAttendance(response.students.id, selectedMonth);
    }
  }

  const filterClick = async (label) => {
    setStartDate(formattedDate)
    setSelectedMonth(label);
    await getAttendance(isStudent?.id, label);
  }

  const [dateRange, setDateRange] = useState([formattedDate, formattedDate]);
  const [rangeStartDate, endDate] = dateRange;
  const [startDateRange, setStartDateRange] = useState(formattedDate);
  const [endDateRange, setEndDateRange] = useState(formattedDate);

  const onChange = async (dates) => {
    const year = dates[0]?.getFullYear();
    const month = (dates[0]?.getMonth() + 1).toString().padStart(2, "0");
    const day = dates[0]?.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setStartDateRange(newDate);
    const endYear = dates[1]?.getFullYear();
    const endMonth = (dates[1]?.getMonth() + 1).toString().padStart(2, "0");
    const endDay = dates[1]?.getDate().toString().padStart(2, "0");
    const endDate = endYear + "-" + endMonth + "-" + endDay;
    setEndDateRange(endDate);
    await getAttendanceRange(isStudentAttendance?.id, newDate, endDate);
  };

  const [searchItemList, setSearchItemList] = useState('')
  const [filteredUsersList, setFilteredUsersList] = useState(null);

  const handleInputChange = async (e) => {
    const searchTerm = e.target.value;
    setSearchItemList(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentListByAssistant(searchTerm);
      const filteredItems = response.data.data.filter((user) =>
        user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsersList(filteredItems);
    }
  }

  const getStudentBySeacrhList = async (student) => {
    setFilteredUsersList(null)
    const response = await getStudentByClassAssistants(student);
    setStudentAttendance(response.students);
      await getAttendanceRange(response.students.id, startDateRange, endDateRange);
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="shadow" style={{ padding: "1rem" }}>
          <CardHeader className="border-0">
            <Row className="attendanceHeader mb-4">
              <Col className="wordingSection">
                <h3 className="mb-0">Attendance</h3>
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="12" xl="5">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange1}
                    placeholder="Search..."
                    style={{ borderBottomLeftRadius: searchItem !== '' ? '0px' : '', borderBottomRightRadius: searchItem !== '' ? '0px' : '' }}
                  />
                  {searchItem && (
                    <ul style={{ position: 'absolute', width: '94%', listStyleType: 'none', padding: '0', border: '1px solid #ddd', zIndex: 10, backgroundColor: 'white', display: filteredUsers === null ? 'none' : '' }}>
                      {filteredUsers?.map((option, index) => (
                        <li key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => getStudentBySeacrh(option.barcode)}>
                          {option.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
              </Col>
              <Col md="6" lg="6" xl="3">
                <FormGroup className="mb-2">
                  <Dropdown
                    isOpen={statusDropdownOpen}
                    toggle={() =>
                      setStatusDropdownOpen(!statusDropdownOpen)
                    }
                    className="filterButton"
                  >
                    <DropdownToggle caret>
                      {selectedMonth ? selectedMonth : "Filter by Month"}
                    </DropdownToggle>
                    {months && months.length > 0 ? (
                      <DropdownMenu>
                        {months.map((month) => (
                          <DropdownItem
                            key={month.label}
                            value={month.label}
                            onClick={() =>
                              filterClick(
                                month.label
                              )
                            }
                          >
                            {month.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    ) : null}
                  </Dropdown>
                </FormGroup>
              </Col>
              <Col md="6" lg="6" xl="4">
                <div className="filterSection">
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => filterAttendanceByDate(date)}
                    className="dateSelector"
                    icon="fa fa-calendar"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {isStudent ?
                <div className="ml-4">
                  Name: {isStudent.full_name} <br />
                  Barcode: {isStudent.barcode} <br />
                  Phone Number: {isStudent.phone_number}
                </div>
                : null}
            </Row>
          </CardHeader>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">In</th>
                <th scope="col">Out</th>
              </tr>
            </thead>
            <tbody>
              {!isTeacherClasses ? (
                <tr style={{ textAlign: "center" }}>
                  <td colSpan={12}>
                    <div>
                      <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
                    </div>
                  </td>
                </tr>
              ) : teacherClasses?.map((teacherClass, index) => (
                <tr key={index}>
                  <td>{teacherClass.in_time?.split(" ")[0]}</td>
                  <td>{(teacherClass.in_time?.split(" ")[1]).split(".")[0]}</td>
                  <td>{teacherClass.out_time !== null && (teacherClass.out_time?.split(" ")[1]).split(".")[0]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
        <Card className="shadow mt-4" style={{ padding: "1rem" }}>
          <CardHeader className="border-0">
            <Row className="attendanceHeader mb-4">
              <Col className="wordingSection">
                <h3 className="mb-0">Attendance</h3>
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="12" xl="5">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItemList}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    style={{ borderBottomLeftRadius: searchItemList !== '' ? '0px' : '', borderBottomRightRadius: searchItemList !== '' ? '0px' : '' }}
                  />
                  {searchItemList && (
                    <ul style={{ position: 'absolute', width: '94%', listStyleType: 'none', padding: '0', border: '1px solid #ddd', zIndex: 10, backgroundColor: 'white', display: filteredUsersList === null ? 'none' : '' }}>
                      {filteredUsersList?.map((option, index) => (
                        <li key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => getStudentBySeacrhList(option.barcode)}>
                          {option.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
              </Col>
              <Col md="6" lg="6" xl="4">
                <div className="filterSection">
                  <DatePicker
                    showIcon
                    selected={rangeStartDate}
                    onChange={(update) => {
                      setDateRange(update);
                      onChange(update)
                    }}
                    className="dateSelector"
                    icon="fa fa-calendar"
                    startDate={rangeStartDate}
                    endDate={endDate}
                    selectsRange={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              {isStudentAttendance ?
                <div className="ml-4">
                  Name: {isStudentAttendance.full_name} <br />
                  Barcode: {isStudentAttendance.barcode} <br />
                  Phone Number: {isStudentAttendance.phone_number}
                </div>
                : null}
            </Row>
          </CardHeader>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">In</th>
                <th scope="col">Out</th>
              </tr>
            </thead>
            <tbody>
              {!isAttendanceList? (
                <tr style={{ textAlign: "center" }}>
                  <td colSpan={12}>
                    <div>
                      <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
                    </div>
                  </td>
                </tr>
              ) : attendanceList?.map((teacherClass, index) => (
                <tr key={index}>
                  <td>{teacherClass.in_time?.split(" ")[0]}</td>
                  <td>{(teacherClass.in_time?.split(" ")[1]).split(".")[0]}</td>
                  <td>{teacherClass.out_time !== null && (teacherClass.out_time?.split(" ")[1]).split(".")[0]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
    </>
  );
};

export default AttendanceReport;
