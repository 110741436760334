import React from 'react';
import QRCode from 'react-qr-code';
import "../../assets/css/qr-card-styles.css";
import Logo from '../../assets/img/brand/nenasala-logo.png';
import Background from '../../assets/img/brand/nenasala-bg.jpg';

const StudentIdBack = ({ student }) => {
    return (
        <div style={styles.card}>
            <div style={styles.header}>
                <img src={Logo} alt="Institute Logo" width={100} crossOrigin="anonymous" />
            </div>
            <div style={styles.studentInfo}>
                <h6 style={{ marginBottom: '0px' }}>STUDENT ID CARD</h6>
            </div>
            <div style={styles.qrCodeContainer}>
                <QRCode 
                    value={student.barcode} 
                    size={80} 
                    className="code" 
                />
            </div>
            <div style={styles.infoTextContainer}>
                <p style={styles.infoText}>කාඩ්පත භාවිතයට අදාල උපදෙස්</p>
                <p style={styles.infoText}>1. පංතියට පැමිණීමේ දී කාඩ්පත රැගෙන ඒම අනිවාර්ය වේ.</p>
                <p style={styles.infoText}>2. කාඩ්පත අන්සතු කිරීම සපුරා තහනම්.</p>
                <p style={styles.infoText}>3. කාඩ්පත ප්‍රවේශම් කර ගැනීම ඔබ සතු වගකීමකි.</p>
                <p style={styles.infoText}>4. මුදල් ගෙවීමේදී සහ පංතියට පැමිණීමේ දී කාඩ්පත ඉදිරිපත් කළ යුතුය.</p>
                <p style={styles.infoText}>5. නැණසල මගින් සංවිධානය වන සෑම කටයුත්තකදීම කාඩ්පත භාවිතා කළ යුතුය.</p>
            </div>
        </div>
    );
};

const styles = {
    card: {
        width: '55mm',
        height: '87mm',
        backgroundColor: '#fff',
        padding: '10px 15px',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflow: 'visible',
    },
    header: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    studentInfo: {
        textAlign: 'center',
        padding: '0px 10px'
    },
    qrCodeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '6px 0',
        width: '100%',
        height: '90px',
        overflow: 'visible',
    },
    infoTextContainer: {
        paddingTop: '5px',
    },
    infoText: {
        fontSize: '8px',
        color: '#000000',
        margin: '2px 0',
        lineHeight: '1.2',
    }
};

export default StudentIdBack;
