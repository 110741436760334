import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import Paginations from "components/Pagination/Paginations";
import { BiEdit } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { addNewExam } from "actions/Exams";
import { filterExams } from "actions/Exams";
import { editExam } from "actions/Exams";

const Exam = () => {

  const ExamType = {
    IT_SCHOOL: "IT_SCHOOL",
    VOCATIONAL_TRAINING: "VOCATIONAL_TRAINING",
  }

  const TimePeriod = {
    NONE: "NONE",
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
    TERMLY: "TERMLY",
    YEARLY: "YEARLY",
  }

  const { teacherClassId, teacherId } = useParams();

  const [allExams, setAllExams] = useState(null);
  const [isExams, setIsExams] = useState(false);
  const [isLoadingExams, setLoadingExams] = useState(true);
  const [editModal, setEditmodal] = useState(false);
  const [selectedExam, setSelectedExam] = useState({
    id: null,
    name: "",
    limit: "",
    status: "",
  });
  const [isData, setData] = useState({
    name: "",
    limit: "",
    teacherClassId: +teacherClassId,
  });

  const [isError, setError] = useState({
    name: "",
    limit: "",
  });
  const [errors, setErrors] = useState({});
  const [editErrors, setEditErrors] = useState({
    name: "",
    limit: "",
  });
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isEdited, setEdit] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [isDisableInsertBtn, setDisableInsertBtn] = useState(false);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [periodDropdownOpen, setPeriodDropdownOpen] = useState(false);
  const [typeEditDropdownOpen, setTypeEditDropdownOpen] = useState(false);
  const [periodEditDropdownOpen, setPeriodEditDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [initialExam, setInitialExam] = useState({
    id: null,
    name: "",
    status: "",
  });

  const fetchData = async (itemsPerPge, skip) => {
    try {
      const tutes = await filterExams(teacherClassId, teacherId, skip, itemsPerPge);
      setPageCount(tutes.data.pageCount);
      setAllExams(tutes.data);
      setLoadingExams(false);
      setIsExams(true);
    } catch (error) {
      setLoadingExams(false);
      setIsExams(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);

  const handlePagination = async (pageNumber) => {
    await fetchData(itemsPerPge, pageNumber - 1);
    setCurrentPage(pageNumber);
  }

  const handleInputChange = (values) => {
    setData({
      ...isData,
      ...values,
    });
  };

  const isValidExam = (value) => {
    const errors = {};
    if (value.name.length < 3) {
      errors.examName =
        "Name must be at least 2 characters long.";
    }
    if (!value.limit) {
      errors.limit =
        "Maximum mark is required.";
    }
    if (!value.exam_type) {
      errors.exam_type =
        "Exam Type is required.";
    }
    if (!value.time_period) {
      errors.time_period =
        "Time Period is required.";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      return true;
    }
  };

  const insertNewExam = async () => {
    try {
      if (isValidExam(isData)) {
        setDisableInsertBtn(true);
        isData.limit = +(isData.limit);
        if (isData.price) {
          isData.price = +(isData.price);
        }
        const response = await addNewExam(isData);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setDisableInsertBtn(false);
          setData({
            name: "",
            maxMarks: ''
          });
          fetchData(itemsPerPge, skip);
        } else {
          setErrorMessage(response.message);
          setFailed(true);
          setDisableInsertBtn(false);
          setData({
            name: "",
            maxMarks: ''
          });
        }
      }
    } catch (error) {
      setErrorMessage("Failed to create the exam. Try again!");
      setFailed(true);
      setDisableInsertBtn(false);
    }
  };

  const handleEditClick = (exam) => {
    setSelectedExam(exam);
    setInitialExam(exam);
    setEditmodal(true);
  };

  const isValidEditedExam = (value) => {
    const errors = {};
    if (value.name.length < 2) {
      errors.name =
        "Name must be at least 2 characters long.";
    }
    if (!value.limit) {
      errors.limit =
        "Maximum mark is required.";
    }
    if (Object.keys(errors).length > 0) {
      setEditErrors(errors);
      return false;
    }
    else {
      return true;
    }
  };

  function getChangedValues(selectedExam) {
    const changedValues = {};
    for (const key in selectedExam) {
      if (
        key !== "id" &&
        selectedExam.hasOwnProperty(key) &&
        selectedExam[key] !== initialExam[key]
      ) {
        changedValues[key] = selectedExam[key];
      }

      if (key === "id") {
        changedValues[key] = selectedExam[key];
      }
    }
    return changedValues;
  }

  const editExamDetails = async () => {
    try {
      const validityCheck = isValidEditedExam(selectedExam);
      if (validityCheck === true) {
        setEdit(false);
        const data = getChangedValues(selectedExam);
        data.limit = + (data.limit);
        data.price = + (data.exam_fee);
        const body = JSON.stringify(data);
        const response = await editExam(body);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setData({
            name: "",
          });
          await fetchData(itemsPerPge, skip);
          setEditmodal(false);
        } else {
          setFailed(true);
        }
      }
    } catch (error) {
      setFailed(true);
      setErrorMessage('Failed to update exam details. Try Again!')
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Exam</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    id="exam"
                    placeholder="Exam Name"
                    type="text"
                    value={isData.name || ''}
                    required
                    onChange={(e) => {
                      handleInputChange({ ...isData, name: e.target.value });
                      setError({ ...isError, examName: '' });
                    }
                    }
                  />
                  {isError.examName && (
                    <p className="text-danger">{isError.examName}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="limit"
                    placeholder="Maximum Mark"
                    type="text"
                    name="limit"
                    value={isData.limit || ''}
                    required
                    pattern="\d*"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleInputChange({ ...isData, limit: e.target.value });
                        setError({ ...isError, limit: '' });
                      }
                    }
                    }
                  />
                  {isError.limit && (
                    <p className="text-danger">{isError.limit}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="price"
                    placeholder="Exam Price"
                    type="text"
                    name="price"
                    value={isData.price || ''}
                    required
                    pattern="\d*"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleInputChange({ ...isData, price: e.target.value });
                        setError({ ...isError, price: '' });
                      }
                    }
                    }
                  />
                  {isError.price && (
                    <p className="text-danger">{isError.price}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    isOpen={typeDropdownOpen}
                    toggle={() => setTypeDropdownOpen(!typeDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {isData.exam_type
                        ? isData.exam_type
                        : "Select a Exam Type"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(ExamType).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={value}
                          onClick={() => [
                            setData({
                              ...isData,
                              exam_type: value,
                            }),
                            setError({ ...isError, exam_type: '' })
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {isError.exam_type && (
                    <p className="text-danger">{isError.exam_type}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    isOpen={periodDropdownOpen}
                    toggle={() => setPeriodDropdownOpen(!periodDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {isData.time_period
                        ? isData.time_period
                        : "Select a Time Period"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(TimePeriod).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={value}
                          onClick={() => [
                            setData({
                              ...isData,
                              time_period: value,
                            }),
                            setError({ ...isError, time_period: '' })
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {isError.time_period && (
                    <p className="text-danger">{isError.time_period}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => insertNewExam()}
                  disabled={isDisableInsertBtn}
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingExams ? (
          <Card>
            <Spinner className="m-10" color="primary" size="sm">Loading...</Spinner>
          </Card>
        ) : !isExams ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-3">Exams</h3>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Maximum Mark</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allExams?.map((exam, index) => (
                  <tr key={index}>
                    <td>{exam.name || ''}</td>
                    <td>{exam.limit || ''}</td>
                    <td>{exam.status ? "Active" : "Inactive" || ''}</td>
                    <td>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/teacher/exam-results/${teacherClassId}/${exam.id}/${exam.limit}`}
                      >
                        Resullts
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        id="editExam"
                        onClick={() => handleEditClick(exam)}
                      >
                        <BiEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => {
            setEditmodal(false);
            editErrors({ name: "" });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Exam Details
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setEditmodal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Input
                      id="exampleFormControlInput1"
                      placeholder="Exam Name"
                      type="text"
                      value={selectedExam ? selectedExam.name : "" || ''}
                      onChange={(e) => {
                        setSelectedExam({
                          ...selectedExam,
                          name: e.target.value,
                        });
                        setEditErrors({ ...editErrors, name: '' })
                        setEdit(true);
                      }}
                    />
                    {editErrors.name && (
                      <p className="text-danger">{editErrors.name}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="exampleFormControlInput2"
                      placeholder="Maximum Mark"
                      type="text"
                      value={selectedExam ? selectedExam.limit : "" || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          setSelectedExam({
                            ...selectedExam,
                            limit: e.target.value,
                          });
                        }
                        setEditErrors({ ...editErrors, limit: '' })
                        setEdit(true);
                      }}
                    />
                    {editErrors.limit && (
                      <p className="text-danger">{editErrors.limit}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                  <Input
                    id="price"
                    placeholder="Exam Price"
                    type="text"
                    name="price"
                    value={selectedExam ? selectedExam.exam_fee : "" || ''}
                    required
                    pattern="\d*"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setSelectedExam({ ...selectedExam, exam_fee: e.target.value });
                        setEditErrors({ ...editErrors, price: '' });
                        setEdit(true);
                      }
                    }
                    }
                  />
                  {editErrors.price && (
                    <p className="text-danger">{editErrors.price}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    isOpen={typeEditDropdownOpen}
                    toggle={() => setTypeEditDropdownOpen(!typeEditDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedExam.exam_type
                        ? selectedExam.exam_type
                        : "Select a Exam Type"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(ExamType).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={selectedExam.exam_type? selectedExam.exam_type : ''}
                          onClick={() => [
                            setSelectedExam({
                              ...selectedExam,
                              exam_type: value,
                            }),
                            setEditErrors({ ...editErrors, exam_type: '' }),
                            setEdit(true),
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {editErrors.exam_type && (
                    <p className="text-danger">{editErrors.exam_type}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    isOpen={periodEditDropdownOpen}
                    toggle={() => setPeriodEditDropdownOpen(!periodEditDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedExam.time_period
                        ? selectedExam.time_period
                        : "Select a Time Period"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(TimePeriod).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={value}
                          onClick={() => [
                            setSelectedExam({
                              ...selectedExam,
                              time_period: value,
                            }),
                            setEditErrors({ ...editErrors, time_period: '' }),
                            setEdit(true),
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {editErrors.time_period && (
                    <p className="text-danger">{editErrors.time_period}</p>
                  )}
                </FormGroup>
                  <FormGroup>
                    {/* <Inpus */}
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        {selectedExam
                          ? selectedExam.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedExam({
                              ...selectedExam,
                              status: true,
                            });
                            setEdit(true);
                          }}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedExam({
                              ...selectedExam,
                              status: false,
                            });
                            setEdit(true);
                          }}
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button
                  color="primary"
                  type="button"
                  disabled={!isEdited}
                  onClick={() => editExamDetails()}
                  style={{ marginLeft: "15px" }}
                >
                  Save
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Exam;
