import { sendMessagesToStudentList } from "actions/Messages";
import { sendMessagesToList } from "actions/Messages";
import { getAllClassStudents } from "actions/Students";
import { findAllClassFees } from "actions/Teachers";
import { findAllAbsentByClass } from "actions/Teachers";
import { findAllAttendanceByClass } from "actions/Teachers";
import { useState } from "react";
import { Form, Button, Card, Col, FormGroup, Input, Row } from "reactstrap";
import "../../assets/css/styles.css";
import "../../assets/css/message-styles.css";
import { sendMessagesToStudents } from "actions/Messages";
import { filterStudentListForMsg } from "actions/Students";
const Messages = ({ details, type, onSendMessage }) => {

    const [isData, setData] = useState({
        message: "",
        user_list: [],
    });

    const [errors, setErrors] = useState({});

    const getAttendance = async () => {
        const teacherClasses = await findAllAttendanceByClass(details.teacherClassId, details.startDate);
        if (teacherClasses.data.length > 0) {
            const messageDetails = { message: isData.message, user_list: teacherClasses.data }
            await sendMessagesToStudents(messageDetails);
            const response = await sendMessagesToStudents(messageDetails);
            onSendMessage(response);
        }
    }

    const getAbsent = async () => {
        const teacherClasses = await findAllAbsentByClass(details.teacherClassId, details.startDate);
        if (teacherClasses.data.length > 0) {
            const messageDetails = { message: isData.message, user_list: teacherClasses.data }
            const response = await sendMessagesToStudents(messageDetails);
            onSendMessage(response);
        }
    }

    const getAllStudents = async () => {
        const teacherClasses = await getAllClassStudents(details.classTeacherId);
        if (teacherClasses.data.length > 0) {
            const messageDetails = { message: encodeURIComponent(isData.message), user_list: teacherClasses.data }
            const response = await sendMessagesToStudentList(messageDetails);
            onSendMessage(response);
        }
    }

    const getAllPaidStudents = async () => {
        const teacherClasses = await findAllClassFees(details.teacherClassId,details.isChecked, details.paymentType, details.monthOrDate, details.selectedMonth);
       if (teacherClasses.data.length > 0) {
            const messageDetails = { message: isData.message, user_list: teacherClasses.data }
            const response = await sendMessagesToStudents(messageDetails);
            onSendMessage(response);
        }
    }

    const getAllActiveStudents = async () => {
        const teacherClasses = await filterStudentListForMsg(details[0], details[1], details[2]);
        if (teacherClasses.data.data.length > 0) {
            const messageDetails = { message: encodeURIComponent(isData.message), user_list: teacherClasses.data.data }
            const response = await sendMessagesToStudents(messageDetails);
            onSendMessage(response);
        }
    }
    const handleInputChange = (values) => {
        setErrors({ message: "" });
        setData({
            ...isData,
            ...values,
        });
    };

    const sendMessage = async () => {
        try {
            if (type === 'attendance') {
                await getAttendance();
            }
            if (type === 'absent') {
                await getAbsent();
            }
            if(type === 'class-students') {
                await getAllStudents();
            }
            if(type === 'paid' || type === 'unpaid') {
                await getAllPaidStudents();
            }
            if(type === 'students') {
                await getAllActiveStudents();
            }
        } catch (error) {
            onSendMessage(error);
        }
    }
    return (
                <Form className="mb-6 ml-4 mt-4">
                    <Row className="messageBox">
                        <Col xs="8" sm="8" style={{paddingRight: "0px"}}>
                            <FormGroup>
                                <Input
                                    id="message"
                                    placeholder="Message"
                                    type="textarea"
                                    value={isData.message}
                                    required
                                    onChange={(e) =>
                                        handleInputChange({ message: e.target.value })
                                    }
                                    className="messageText"
                                />
                                {errors.message && (
                                    <p className="text-danger">{errors.message}</p>
                                )}
                            </FormGroup>
                            </Col>
                            <Col xs="4" sm="4">
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => sendMessage()}
                                disabled={!isData.message}
                                className="msgSendBtn"
                            >
                                Send
                            </Button>
                        </Col>
                    </Row>
                </Form>
    );
}

export default Messages;